.waitlist-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

.waitlist-form-container {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  position: relative;
  font-family: inherit;
  text-align: center;
}

.waitlist-form-container h2 {
  margin-bottom: 1rem;
  font-family: inherit;
  color: #c964df;
}

.waitlist-form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: inherit;
}

.waitlist-form-container input,
.waitlist-form-container textarea {
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
}

.waitlist-form-container input:focus,
.waitlist-form-container textarea:focus {
  border-color: #9c27b0;
}

.waitlist-form-container textarea {
  resize: vertical;
  min-height: 100px;
}

.waitlist-form-container .cta-button {
  margin-top: 1rem;
  background-color: #c964df;
  color: #FFFFFF;
  border: none;
  border-radius: 30px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: inherit;
  width: 100%;
  max-width: 200px;
}

.waitlist-form-container .cta-button:hover {
  background-color: #ba68c8;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #000000;
  z-index: 1001;
  padding: 5px 10px;
  transition: color 0.3s ease;
  font-family: inherit;
}

.close-button:hover {
  color: #9c27b0;
}
